
import { Component, Vue, Inject, Prop, Watch } from "vue-property-decorator";
import Util from "../../../lib/util";
import AbpBase from "../../../lib/abpbase";
import PageRequest from "../../../store/entities/page-request";
// import EditCampana from "./edit-campana.vue";
import PeopleFind from "../../../components/People/people-find.vue";
import Campana from "../../../store/entities/OZONE/campana";
import { Moment } from "moment";
import moment from "moment";
import { get } from "js-cookie";
class PageExportacionRequest extends PageRequest {
  keyword: string;
  fechaInicio: Date;
  fechaFin: Date;
  campana: Campana;
  enviada: Boolean;
  emitida: Boolean;
  pendiente: Boolean;
  rechazada: Boolean;
  ids: Array<number>;
}

@Component({
  components: {
    PeopleFind,
  },
})
export default class Exportaciones extends AbpBase {
  //filters
  pagerequest: PageExportacionRequest = new PageExportacionRequest();
  creationTime: Date[] = [];
  authorsHeaderBtns: String = "all";
  createModalShow: boolean = false;
  editModalShow: boolean = false;
  findModalShow: boolean = false;
  clienteNombre: string = "";
  fechaEmision: Moment = null;
  selectedTransferencias: Array<number> = [];

  onChange = (e) => {
    var id = e.target.id;
    var value = e.target.checked;
    if (value) {
      this.selectedTransferencias.push(id);
    } else {
      const index = this.selectedTransferencias.indexOf(id);
      if (index > -1) {
        this.selectedTransferencias.splice(index, 1);
      }
    }
  };

  campana: Campana = null;

  searchDefinition: {}[] = [
    {
      fieldName: "Busqueda",
      fieldType: "text",
      fieldBind: "keyword",
      value: "",
    },
    {
      fieldName: "Fecha Inicio",
      fieldType: "date",
      fieldBind: "fechaInicio",
      value: "",
    },
    {
      fieldName: "Fecha Fin",
      fieldType: "date",
      fieldBind: "fechaFin",
      value: "",
    },
    {
      fieldName: "Campaña",
      fieldType: "custom",
      custom: "select-campana",
      fieldBind: "campana",
      value: this.campana,
    },
    {
      fieldName: "Pendiente",
      fieldType: "checkbox",
      fieldBind: "pendiente",
      value: false,
    },
    {
      fieldName: "Enviada",
      fieldType: "checkbox",
      fieldBind: "enviada",
      value: false,
    },
    {
      fieldName: "Emitida",
      fieldType: "checkbox",
      fieldBind: "emitida",
      value: false,
    },
    {
      fieldName: "Rechazada",
      fieldType: "checkbox",
      fieldBind: "rechazada",
      value: false,
    },
  ];

  pagination: any = {};

  get list() {
    return this.$store.state.exportacion.list;
  }
  get loading() {
    return this.$store.state.exportacion.loading;
  }

  getSearch = async (model) => {
    model.forEach((element) => {
      if (element.value) {
        this.pagerequest[element.fieldBind] = element.value;
      } else {
        delete this.pagerequest[element.fieldBind];
      }
    });
    await this.getpage();
    this.findModalShow = false;
  };

  async hide() {
    this.findModalShow = false;
  }
  async find() {
    this.findModalShow = true;
  }

  pageChange(page: number) {
    this.$store.commit("exportacion/setCurrentPage", page);
    this.getpage();
  }

  pagesizeChange(pagesize: number) {
    this.$store.commit("exportacion/setPageSize", pagesize);
    this.getpage();
  }

  cancelarEmitido(e) {
    this.fechaEmision = null;
  }
  async emitido(sepa) {
    await this.$store
      .dispatch({
        type: "exportacion/marcarEmitido",
        data: {
          id: sepa.id,
          fechaExportacion: this.fechaEmision.format("YYYY/MM/DD"),
        },
      })
      .then(async () => {
        this.$message.success(
          "Fichero y transferencias marcadas como emitidas."
        );
        await this.getpage();
      });
  }

  async getpage() {
    this.pagerequest.maxResultCount = this.pageSize;
    this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

    await this.$store.dispatch({
      type: "exportacion/getAll",
      data: this.pagerequest,
    });

    const pagination = { ...this.pagination };
    pagination.total = this.$store.state.exportacion.totalCount;
    pagination.pageSize = this.$store.state.exportacion.pageSize;
    pagination.current = this.$store.state.exportacion.currentPage;
    this.pagination = pagination;
  }

  get pageSize() {
    return this.$store.state.exportacion.pageSize;
  }

  get totalCount() {
    return this.$store.state.exportacion.totalCount;
  }

  get currentPage() {
    return this.$store.state.exportacion.currentPage;
  }

  set currentPage(page) {
    this.$store.commit("exportacion/setCurrentPage", page);
    this.getpage();
  }

  handleTableChange = async (pagination, filters, sorter) => {
    this.$store.commit("exportacion/setCurrentPage", pagination.current);
    this.getpage();

    await this.getpage();
  };

  editRow(item, index, button) {
    //this.edit()
    
    this.$router.push({ name: "transferlist", query: { exp: item.id } });
  }

  async download(type, fileName, id) {
    this.pagerequest.ids = this.selectedTransferencias;
    await this.$store.dispatch({
      type: type == 1 ? "exportacion/downloadSEPA" : "exportacion/downloadReport",
      data: { id:  id},
    }).then((data) => {
      
        let blob = this.dataURLtoFile("data:text/" +  (type == 1 ? "xml" : "csv") + ";base64," + data, fileName);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    });
  }

  dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  stateColorScheme(state: number) {
    switch (state) {
      case 1:
        return "ant-tag-primary";
      case 3:
        return "ant-tag-secondary";
      case 4:
        return "ant-tag-success";
      case 5:
        return "ant-tag-danger";
    }
  }

  columns = [
    {
      title: this.L("Cuenta Emisora"),
      scopedSlots: { customRender: "func" },
    },
    {
      title: this.L("Fichero"),
      scopedSlots: { customRender: "files" },
    },
    {
      title: this.L("Pendiente"),
      dataIndex: "pendiente",
      scopedSlots: { customRender: "pending" },
    },
    {
      title: this.L("Transferencias"),
      scopedSlots: { customRender: "trans" },
    },
    {
      title: this.L("Fecha Creacion"),
      dataIndex: "fechaCreacion",
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.L("Fecha Emisión"),
      dataIndex: "fechaExportacion",
      scopedSlots: { customRender: "date" },
    },
    {
      scopedSlots: { customRender: "editBtn" },
      width: 10,
      title: "",
    },
    {
      scopedSlots: { customRender: "removeBtn" },
      width: 10,
      title: "",
    },
  ];

  async created() {
    this.getpage();
  }
}
